import PHONE from '../constants/phonenumber';

const Sidebar = () => {
	return (
		<>
			<div className="sidebar-box">
				<h2>Jetzt schon bieten</h2>
				<p>
					Sie können auf{' '}
					<a
						href="https://www.philasearch.com/de/categories?set_anbieter=9514&set_auktionnr=10602"
						target="_blank"
						rel="noreferrer"
					>
						philasearch.com
					</a>{' '}
					schon jetzt Ihre Gebote abgeben. Wir haben keine Einsicht
					auf Ihre Gebote.
				</p>
			</div>

			<div className="sidebar-box">
				<h2>Sie wollen verkaufen</h2>
				<p>
					Bitte kontaktieren Sie uns. Wir beraten Sie und unterbreiten
					Ihnen ein für Sie zugeschnittenes Angebot.
				</p>
			</div>

			<div className="sidebar-box">
				<h2>Wir sind für Sie da</h2>
				<b>Montag bis Freitag</b>
				<br />
				<p>
					von 9–12 Uhr und von 13–17 Uhr
					<br />
					Telefon: {PHONE}
					<br />
					E-Mail:{' '}
					<a href="mailto:info@rj-stamp.ch">info@rj-stamp.ch</a>
				</p>
			</div>
			<div className="sidebar-box">
				<h2>Aktueller Auktionskatalog</h2>
				<p>Zum Durchblättern auf das Cover klicken.</p>
				<a
					href={
						'catalogue/Auction_3/Auction_3_2024_06_15/Auction_3_2024_06_15.html'
					}
					target="_blank"
					rel="noreferrer"
				>
					<div
						className={
							'd-flex justify-content-center align-items-center'
						}
						style={{
							backgroundColor: 'black',
							marginBottom: '10px',
						}}
					>
						<img
							src={'catalogue/Auction_3/cover.jpg'}
							alt={'Auction 3 Cover'}
							style={{ maxWidth: '200px' }}
							className={'img-fluid'}
						/>
					</div>
				</a>
			</div>
		</>
	);
};

export default Sidebar;
