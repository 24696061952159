import PHONE from '../constants/phonenumber';

export default function AuctionConditions() {
	return (
		<>
			<h1>Versteigerungsbedingungen</h1>
			<hr />
			<ol className="versteigerungsbedingungen">
				<li>
					Die Teilnahme an der Versteigerung ist freiwillig und
					erfolgt gegen Rechnung in Schweizer Franken. Fremde
					Währungen werden zum Tageskurs einer Schweizer Grossbank
					angerechnet, wobei der Tag des Zahlungseingangs Gültigkeit
					hat.
				</li>
				<li>
					Der Versteigerer kann Personen ohne Angabe von Gründen von
					der Auktion ausschliessen.
				</li>
				<li>
					Die Ausrufpreise sind Startgebote, Untergebote werden zum
					Schutz der Einlieferer nicht berücksichtigt.
				</li>
				<li>
					Den Zuschlag erhält der Meistbietende. Der Zuschlag erfolgt
					so niedrig wie möglich, also nicht zwingend zum abgegebenen
					Höchstgebot, sondern eine Steigerungsstufe über dem
					zweithöchsten Gebot. Beträgt das Gebot eines Bieters für ein
					Los z.B. CHF 1’000.–, das zweithöchste Gebot jedoch nur Fr.
					600.–, so erfolgt der Zuschlag für CHF 650.–. Bei gleich
					hohen Geboten erhält das frühere Gebot den Zuschlag.
				</li>
				<li>
					Der Zuschlag verpflichtet zur Abnahme der ersteigerten
					Artikel. Wer für Dritte bietet, haftet neben dem Dritten als
					Solidarschuldner. Mit dem Zuschlag geht die Gefahr auf den
					Bieter über.{' '}
				</li>
				<li>
					Zur Zuschlagssumme wird ein Aufgeld von 22% erhoben und pro
					Los eine Losgebühr von CHF 3.–. Hinzu kommen die Kosten für
					Porto, Verpackung, Versand und Versicherung. Auf den
					Gesamtbetrag wird die gesetzliche MwSt. von zur Zeit 8.1%
					erhoben. Die MwSt. entfällt bei einem Versand ins Ausland.
				</li>
				<li>
					Die Mindeststeigerungsstufen betragen …
					<table className="table table-bordered table-striped mb-2 mt-2">
						<tbody>
							<tr>
								<td></td>
								<td>bis CHF 50.–</td>
								<td>CHF 2.–</td>
							</tr>
							<tr>
								<td>von CHF 50.–</td>
								<td>bis CHF 100.–</td>
								<td>CHF 5.–</td>
							</tr>
							<tr>
								<td>von CHF 100.–</td>
								<td>bis CHF 200.–</td>
								<td>CHF 10.–</td>
							</tr>
							<tr>
								<td>von CHF 200.–</td>
								<td>bis CHF 500.–</td>
								<td>CHF 20.–</td>
							</tr>
							<tr>
								<td>von CHF 500.–</td>
								<td>bis CHF 1’000.–</td>
								<td>CHF 50.–</td>
							</tr>
							<tr>
								<td>von CHF 1’000.–</td>
								<td>bis CHF 2’000.–</td>
								<td>CHF 100.–</td>
							</tr>
							<tr>
								<td>von CHF 2’000.–</td>
								<td>bis CHF 5’000.–</td>
								<td>CHF 200.–</td>
							</tr>
							<tr>
								<td>von CHF 5’000.–</td>
								<td>bis CHF 15’000.–</td>
								<td>CHF 500.–</td>
							</tr>
							<tr>
								<td>von CHF 15’000.–</td>
								<td>bis CHF 30’000.–</td>
								<td>CHF 1’000.–</td>
							</tr>
							<tr>
								<td>von CHF 30’000.–</td>
								<td>bis CHF 50’000.–</td>
								<td>CHF 2’000.–</td>
							</tr>
							<tr>
								<td>ab CHF 50’000.–</td>
								<td></td>
								<td>CHF 5’000.– </td>
							</tr>
						</tbody>
					</table>
					Zwischengebote werden auf die nächst höhere Steigerungsstufe
					aufgerundet.
				</li>
				<li>
					Der Versand der Lose erfolgt in der Regel per Rechnung, es
					liegt jedoch im Ermessen der R&J universal AG,
					Vorausrechnungen zu stellen. Die Auktionsrechnung ist
					innerhalb von 10 Kalendertagen nach Erhalt zu begleichen.
					Bei Überschreitung der Zahlungsfrist ist die R&J universal
					AG berechtigt, Zinsen in Höhe von 1% per Monat zu berechnen.
					Der in Verzug geratene Käufer haftet zudem für Mahn- und
					Inkassokosten. Andere, individuelle Zahlungsbedingungen
					müssen vor der Auktion schriftlich festgehalten werden.
				</li>
				<li>
					Die ausgehändigten Lose bleiben bis zur vollständigen
					Bezahlung das Eigentum der R&J universal AG und dürfen nicht
					weiter veräussert werden.{' '}
				</li>
				<li>
					Alle Lose sind gewissenhaft und mit grösster Sorgfalt
					beschrieben. Die Beschreibungen stellen jedoch keine
					zugesicherten Eigenschaften dar. Bei fotografierten Marken
					ist für Rand, Zähnung, Schnitt, Stempel, Unterlage etc. die
					Abbildung massgebend. Fehler, die auf den Abbildungen
					erkennbar sind, können nicht beanstandet werden.
				</li>
				<li>
					In der Beschreibung aufgeführte Katalogwerte dienen einzig
					der Orientierung. Je nach Qualität des Loses kann der
					effektive Wert des Loses tiefer oder höher als der
					angegebene Katalogwert sein. Der aufgeführte Katalogwert ist
					gewissenhaft beschrieben, stellt aber wie der ganze
					Beschrieb der Lose keine zugesicherte Eigenschaft dar.{' '}
				</li>
				<li>
					Bei geprüften Marken anerkennt der Käufer die in den
					Losbeschreibungen erwähnten Atteste und Signaturen als
					verbindlich, insbesondere auch hinsichtlich der Echtheit und
					der Qualität der Lose. Reklamationen sind ausgeschlossen.{' '}
				</li>
				<li>
					Bei ungeprüften Marken haftet die R&J universal AG während
					der Dauer von fünf Jahren ab dem Zeitpunkt des Zuschlags für
					die Echtheit der Briefmarken.{' '}
				</li>
				<li>
					Bei Sammlungen, Doublettenposten und Losen von mehr als drei
					Marken ist jegliche Haftung der R&J universal AG
					ausgeschlossen. Die Beschreibungen und die Abbildungen von
					Sammlungen stellen keine zugesicherten Eigenschaften dar.
					Die Abbildungen sind keine Garantien bezüglich Qualität,
					Echtheit und Zustand der Marken.
				</li>
				<li>
					Eine Besichtigung der Lose ist nach Vereinbarung möglich.
					Termine sind vorgängig per Mail (
					<a href="mailto:info@rj-stamp.ch">info@rj-stamp.ch</a>) oder
					Telefon ({PHONE}) abzustimmen.{' '}
				</li>
				<li>
					Beanstandungen oder Reklamationen aller Art müssen bis
					spätestens 5 Kalendertage nach Erhalt der Ware in
					schriftlicher Form (E-Mail oder per Post) bei der R&J
					universal AG vorliegen.{' '}
				</li>
				<li>
					Der Versteigerer hat das Recht, von der ursprünglichen
					Reihenfolge der Versteigerung abzuweichen, sowie Lose
					zusammenzulegen oder zurückzuziehen.{' '}
				</li>
				<li>
					Der Versteigerer haftet nicht für technische Probleme
					während der Versteigerung (z.B. Unterbruch der
					Internetverbindung).{' '}
				</li>
				<li>
					Schriftliche Aufträge werden gewissenhaft und
					interessewahrend aber ohne Gewähr entgegengenommen. Wir
					empfehlen dringend, Ihre Gebote direkt auf{' '}
					<a
						href="https://philasearch.com"
						rel="noreferrer"
						target="_blank"
					>
						philasearch.com
					</a>{' '}
					zu platzieren.
				</li>
				<li>
					Es gilt ausschliesslich das Schweizerische Recht.
					Gerichtsstand für beide Parteien sind die ordentlichen
					Gerichte am Sitz der R&J universal AG in 6331 Hünenberg,
					Schweiz. Die R&J universal AG behält sich jedoch das Recht
					vor, den Schuldner an seinem Wohnort zu verklagen.
				</li>
				<li>
					Die Versteigerungsbedingungen werden durch die Abgabe eines
					Auktionsauftrages respektive einer Gebotsabgabe auf{' '}
					<a
						href="https://philasearch.com"
						rel="noreferrer"
						target="_blank"
					>
						philasearch.com
					</a>{' '}
					in vollem Umfang anerkannt.
				</li>
			</ol>
		</>
	);
}
